<template>
  <footer>
    <div class="inner">
      <div>
        <span>developed by: <a href="https://kollektiv17.de" target="blank">Kollektiv17</a></span>
      </div>
      <div class="legalContainer">
       <a target="blank" href="https://www.hofer-filmtage.com/de/impressum">Impressum</a>
       <a target="blank" href="https://www.hofer-filmtage.com/de/datenschutz">Datenschutz</a>
      </div>
    </div>
    
  </footer>
</template>

<script>
  export default {
    name: 'Footer'
  }
</script>
<style scoped>

footer {
  width: 100%;
  border-top: 4px solid var(--hof-color-white);
  width: 100%;
  color: var(--hof-color-white);
}

footer .inner {
  width: 100%;
  max-width: 800px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: var(--hof-spacing-s);
}

footer .inner .legalContainer {
  display: flex;
}
footer .inner .legalContainer a {
  display: block;
  margin-left: 12px;
}
</style>