import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import de from './locales/de.json'

function loadLocaleMessages () {
  const locales = [{ en: en }, { de: de }]
  const messages = {}
  locales.forEach(lang => {
    const key = Object.keys(lang)
    messages[key] = lang[key] 
  })
  return messages
} 
const i18n = createI18n({
  locale: navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: 'de',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
  globalInjection: true,
  legacy: false // for composition api
})

export default i18n
