<template>
  <Header backButton />
  <div class="subpageContainer">
    <component :is="selectedComponent"></component>
    <MoreContent />
    </div>  
  <Footer />
</template>

<script>

import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import MoreContent from "../components/MoreContent.vue";
import Year1967 from "../components/year/Year1967View.vue";
import Year1982 from "../components/year/Year1982View.vue";
import Year1984 from "../components/year/Year1984View.vue";
import Year1994 from "../components/year/Year1994View.vue";
import Year2016 from "../components/year/Year2016View.vue";
import Year2020 from "../components/year/Year2020View.vue";
export default {
  name: '1972',
  components: {
    Footer,
    Header,
    Year1967,
    Year1982,
    Year1984,
    Year1994,
    Year2016,
    Year2020,
    MoreContent
  },
  data () { return {
      year: 1967,
      selectedComponent: 'Year1967'
    }
  },
  created () {
    this.year = Number.parseInt(this.$route.params.year, 10) > 0 ? Number.parseInt(this.$route.params.year, 10) : 1978
    if (this.$route.query.locale === 'en') {
      this.$i18n.locale = 'en'
    }
    switch (this.year) {
      case 1967:
        this.selectedComponent = 'Year1967'
        break
      case 1982:
        this.selectedComponent = 'Year1982'
        break
       case 1984:
        this.selectedComponent = 'Year1984'
        break
      case 1994:
        this.selectedComponent = 'Year1994'
        break
      case 2016:
        this.selectedComponent = 'Year2016'
        break
      case 2020:
        this.selectedComponent = 'Year2020'
        break
    } 
    window._paq.push(['trackPageView', this.$route.params.year])
  }
};
</script>
<style scoped></style>