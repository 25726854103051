<template>
  <h1>{{ $t('years.1967.title') }}</h1>
  <p v-html="$t('years.1967.paragraph')"></p>
  <figcaption>{{ $t('years.1967.imageCaption1') }}</figcaption>
</template>

<script>


export default {
  name: '1967',
  components: {
  },
  created () {
    window._paq.push(['trackPageView', '1967'])
  }
};
</script>
<style scoped></style>