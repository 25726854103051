import { createRouter, createWebHistory } from 'vue-router'
import XRView from '../views/XRView.vue'
import HomeView from '../views/HomeView.vue'
import MapView from '../views/MapView.vue'
import YearWrapper from '../views/YearWrapper.vue'

const router = createRouter({
	//start at beginning of page everytime routed
	scrollBehavior: () => ({ x: 0, y: 0 }),
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
		{
			path: '/',
			name: 'home',
			component: HomeView,
		},
    {
      path: '/xr-view',
      name: 'xrview',
      component: XRView
    },
    {
      path: '/map',
      name: 'map',
      component: MapView
    },
    {
      path: '/year/:year*',
      name: 'year',
      component: YearWrapper
    }
  ]
})

export default router
