<template>
  <h1>{{ $t('years.2020.title') }}</h1>
  <p v-html="$t('years.2020.paragraph')"></p>
  <Splide ref="mySplide" :options="{ drag:true  }">
    <SplideSlide>
      <figure>
        <img :src="slideImage1" alt="Hier noch richtigen Text einfügen">
        <figcaption>{{ $t('years.2020.imageCaption1') }}</figcaption>
      </figure>
    </SplideSlide>
    <SplideSlide>
      <figure>
        <img :src="slideImage2" alt="Hier noch richtigen Text einfügen">
        <figcaption>{{ $t('years.2020.imageCaption2') }}</figcaption>
      </figure>
    </SplideSlide>
    <SplideSlide>
      <figure>
        <img :src="slideImage3" alt="Hier noch richtigen Text einfügen">
        <figcaption>{{ $t('years.2020.imageCaption3') }}</figcaption>
      </figure>
    </SplideSlide>
    <SplideSlide>
      <figure>
        <img :src="slideImage4" alt="Hier noch richtigen Text einfügen">
        <figcaption>{{ $t('years.2020.imageCaption4') }}</figcaption>
      </figure>
    </SplideSlide>
    <SplideSlide>
      <figure>
        <img :src="slideImage5" alt="Hier noch richtigen Text einfügen">
        <figcaption>{{ $t('years.2020.imageCaption5') }}</figcaption>
      </figure>
    </SplideSlide>
  </Splide>
</template>

<script>

import slideImage1 from "@/assets/imgs/years/2020/impression1.jpg"
import slideImage2 from "@/assets/imgs/years/2020/impression2.jpg"
import slideImage3 from "@/assets/imgs/years/2020/impression3.jpg"
import slideImage4 from "@/assets/imgs/years/2020/impression4.jpg"
import slideImage5 from "@/assets/imgs/years/2020/impression5.jpg"
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

export default {
  name: '2020',
  components: {
    Splide,
    SplideSlide,
  },
  data () {
		return {
			slideImage1: slideImage1,
      slideImage2: slideImage2,
      slideImage3: slideImage3,
      slideImage4: slideImage4,
      slideImage5: slideImage5
		}
	},
  created () {
    window._paq.push(['trackPageView', '2020'])
  }
};
</script>
<style scoped></style>