<template>
  <header>
    <div class="inner">
			<div class="leftContainer">
				<button v-if="backButton" @click="$router.push('/?year=' + year + '')" class="backButton"></button>
				<div class="hftHeader__brand">
					<a :href="'/?year=' + year" class="hftHeaderLogo hftHeader__brandLogo">
						<div class="hftHeaderLogo__brand">
							<span>Internationale</span>
							<span>Hofer</span>
							<span>Filmtage</span>
						</div>
					</a>
				</div>
			</div>
      <div class="languageSwitchContainer">
        <span class="languageSwitch" @click="toggleLanguage">{{languageSwitchButton }}</span>
			</div>
    </div>
  </header>
</template>

<script>
  export default {
    name: 'Header',
    props: {
      title: {
        type: String,
      },
      backButton: {
        type: Boolean,
        required: false
      }
    },
    computed: {
      languageSwitchButton() {
        return this.$i18n.locale === 'en' ? 'EN' : 'DE'
      }
    },
    created() {
      if(this.$route.params.year) {
        this.year = this.$route.params.year[0]
      }
      if(this.$route.query.year) {
        this.year = this.$route.query.year
      }
    },
    data () {
      return {
        year: 2023
      }
    },
    methods: {
    toggleLanguage() {
      const newLocale = this.$i18n.locale === 'en' ? 'de' : 'en'
      this.$i18n.locale = newLocale
      // change browser url:
      if (this.$route.query.locale != null) {
        const searchURL = new URL(window.location);
        searchURL.searchParams.set('locale', newLocale);
        window.history.pushState({}, '', searchURL);
      }
    }
  }
  }
</script>../

<style scoped>
  header {
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    height:  107px;
    z-index: 2000;
    border-bottom: 4px solid var(--hof-color-white);
    background-color: var(--hof-color-black);
  }

  header .inner {
    max-width: 800px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  span.languageSwitch {
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
  }
  header .languageSwitchContainer {
    display: flex;
    align-self: center;
    margin-right: 24px;
  }
	header .leftContainer {
		display: flex;
		align-items: center;
	}
  .backButton {
    width: 24px;
    height: 32px;
		margin-left: 24px;
    background-image: url("../assets/imgs/backButton.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    color: #fff;
    border: none;
    background-color: transparent;
	}
  h1 {
    font-size: 24px;
    padding: 0;
    text-align: center;
    margin: 0;
    line-height: 70px
  }
  @media (min-width: 768px) {
    h1 {
    	font-size: 36px;
  	}
  }
  .hftHeader__brand {
    padding: 20px 24px;
    position: relative;
    z-index: 10;
	}
  .hftHeaderLogo__brand {
    border-bottom: 2px solid;
    border-top: 2px solid;
    padding: 4px 20px 4px 0;
    position: relative;
    z-index: 2;
  }
  .hftHeaderLogo__brand>span {
      display: block;
      white-space: nowrap;
  }
  .hftHeaderLogo {
    color: white;
    display: flex;
    flex-flow: row nowrap;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2em;
    line-height: 1.1em;
    text-decoration: none;
    text-transform: lowercase;
  }
</style>
