<template>
  <Header backButton title="Plakatorte" />
  <div class="mapPage">
    <div class="mapContainer">
      <Map />
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Map from "@/components/Map.vue";

export default {
	name: 'MapView',
  components: {
    Header,
    Footer,
    Map
  }
};
</script>
<style scoped>
.mapContainer {
	padding-top: 105px;
  height: 100dvh;
}

@media (min-width: 1024px) {
  .mapContainer {
    height: calc(100vh - 120px);
  }
}

</style>