<template>
  <p class="moreContent">
    {{ $t('moreContentText') }}<br />
    <a class="openMapLink" href="" @click="$router.push('/map')">{{ $t('moreContentLink') }}</a>
  </p>
</template>

<script>

export default {
  name: "More Content",
  data() {
    return {
    };
  }
};
</script>
<style scoped>

</style>