<template>
  <h1>{{ $t('years.1984.title') }}</h1>
  <p v-html="$t('years.1984.paragraph')"></p>
  <figure>
    <img :src="slideImage1" alt="Hier noch richtigen Text einfügen">
    <figcaption>{{ $t('years.1984.imageCaption1') }}</figcaption>
  </figure>
</template>

<script>

import slideImage1 from "@/assets/imgs/years/1984/nightmare_poster.jpg"
export default {
  name: '1984',
  components: {
  },
  data () {
		return {
			slideImage1: slideImage1,
		}
	},
  created () {
    window._paq.push(['trackPageView', '1984'])
  }
};
</script>
<style scoped></style>