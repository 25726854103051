<template>
  <div id="map"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import pinIcon from "@/assets/imgs/pin.svg"


export default {
  name: "Map",
  data() {
    return {
      map: null,
      markers: [
        {
          coordinates: [50.319008243067735, 11.915637540037332],
          link: "https://maps.google.com/?q=50.319008243067735,11.915637540037332",
          popupContent: "So geht's zum 1967er Plakat"
        },
        {
          coordinates: [50.31833346441129, 11.915685819796652],
          link: "https://maps.google.com/?q=50.31833346441129,11.915685819796652",
          popupContent: "So geht's zum 1982er Plakat"
        },
        {
          coordinates: [50.31840294677227, 11.9157786754688],
          link: "https://maps.google.com/?q=50.31840294677227,11.9157786754688",
          popupContent: "So geht's zum 1984er Plakat"
        },
        {
          coordinates: [50.31342655849244, 11.91601595220644],
          link: "https://maps.google.com/?q=50.31342655849244,11.91601595220644",
          popupContent: "So geht's zum 1994er Plakat"
        },
        {
          coordinates: [50.315506596875984, 11.91518205057201],
          link: "https://maps.google.com/?q=50.315506596875984,11.91518205057201",
          popupContent: "So geht's zum 2016er Plakat"
        },
        {
          coordinates: [50.31407665539857, 11.917372317162794],
          link: "https://maps.google.com/?q=50.31407665539857,11.917372317162794",
          popupContent: "So geht's zum 2020er Plakat"
        }
      ]
    };
  },
  mounted() {
    this.map = L.map('map', {
      center: [50.315506596875984, 11.91518205057201],
      zoom: 15,
      scrollWheelZoom: false,
      detectRetina: true,
      dragging:false,
      touchZoom: true
  });
    this.addMarkers();

    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

  },
  methods: {
    addMarkers() {
      this.markers.forEach(marker => {
        const link = `<a href="${marker.link}" target="_blank" rel="noopener norefferer">${marker.popupContent}</a>`;
        L.marker(marker.coordinates, { icon: L.icon({ iconUrl: pinIcon, iconSize: [30, 30] }) })
          .addTo(this.map)
          .bindPopup(link);
      })
    }

  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }
};
</script>
<style scoped>

</style>