<template>
  <h1>{{ $t('years.2016.title') }}</h1>
  <p v-html="$t('years.2016.paragraph')"></p>
  <video width="320" height="240" controls>
    <source :src="video" type="video/mp4">
  </video>
  <figcaption>{{ $t('years.2016.imageCaption1') }}</figcaption>
</template>

<script>

import video from "@/assets/videos/years/2016/lars_eidinger.mp4"
export default {
  name: '2016',
  components: {
  },
  data () {
		return {
			video: video
		}
	},
  created () {
    window._paq.push(['trackPageView', '2016'])
  }
};
</script>
<style scoped></style>